import { useState } from 'react';
import './App.css';

const countWords = text => {
  const words = text.match(/[\w'-]+/g);
  return words ? words.length : 0;
}

function App() {
  const [wordGoal, setWordGoal] = useState(50);
  const [wordCount, setWordCount] = useState(0);
  const handleInput = e => setWordCount(countWords(e.target.value));
  const handleChange = e => setWordGoal(parseInt(e.target.value, 10));

  return (
    <div className="h-full grid grid-cols-12">
      <div className="relative col-span-9">
        <textarea
          className="w-full h-full p-10 leading-loose m-0 text-3xl font-serif"
          onInput={handleInput}
          placeholder="Start writing..."
        />
        <div className={`absolute top-4 right-4 text-green-500 ${wordCount >= wordGoal ? 'block' : 'hidden'}`}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" className="w-16 h-16">
            <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
          </svg>
        </div>
      </div>
      <div className="col-span-3 p-4 text-white bg-black">
        <div className="flex justify-between items-center gap-3">
          <label
            htmlFor="word-goal"
            className="font-bold">
              Word Goal
          </label>
          <input
            id="word-goal"
            type="number"
            className="w-16 text-white bg-transparent font-bold border-white"
            defaultValue={wordGoal}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default App;
